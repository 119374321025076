import { createTheme } from "@mui/material/styles"
import WhiteLabelsPartners from "main/white-label/WhiteLabel"
import CustomOrange from "styles/colors/CustomOrange"

export const MobileView = "(max-width:1024px)"
export const secondaryColor: string = CustomOrange[300]

export const createMaterialTheme = () => {
  const { chikoo, nayasavera, traxshop, tapshop, islamabadfoodies } =
    WhiteLabelsPartners

  const getTheme = () => {
    const getLink = window.location.href
    const getTheme = getLink.includes(nayasavera.tld)
      ? nayasavera.theme
      : getLink.includes(tapshop.tld)
        ? tapshop.theme
        : getLink.includes(traxshop.tld)
          ? traxshop.theme
          : getLink.includes(islamabadfoodies.tld)
            ? islamabadfoodies.theme
            : chikoo.theme

    return getTheme
  }
  const themeColors: any = getTheme()

  const primaryColor = themeColors?.primary
  const secondaryColor = themeColors?.secondary
  const secondaryBlack = themeColors?.textDark

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: "#fff"
      },
      secondary: {
        main: secondaryBlack
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            letterSpacing: 2,
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Poppins",
            borderRadius: 8
          },
          outlinedPrimary: {
            color: primaryColor,
            background: "white",
            border: `1px solid ${primaryColor}`
          },
          outlinedSecondary: {
            color: secondaryBlack,
            background: "white",
            border: `1px solid ${secondaryBlack}`
          },
          contained: {
            color: "white"
          },
          containedPrimary: {
            background: primaryColor
          },
          containedSecondary: {
            background: secondaryColor
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "12px"
          }
        }
      }
    },
    typography: {
      fontFamily: "'Nunito'",
      fontWeight: 400,
      allVariants: {
        color: secondaryBlack
      },
      h2: {
        fontWeight: 700,
        fontSize: 32,
        fontFamily: "Poppins"
      },
      h5: {
        fontWeight: 700,
        fontSize: 18,
        fontFamily: "Poppins"
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 600
      },
      h3: {
        fontSize: 24,
        fontWeight: 600,
        fontFamily: "Poppins"
      },
      h4: {
        fontWeight: 700,
        fontFamily: "Poppins"
      }
    },

    snackBar: {
      color: "#FFFFFF",
      error: {
        background: "#FF4D4E"
      }
    },
    custom: {
      white: "#FFFFFF",
      lightgrey: "#969696",
      whiteGrey: "#EFEFEF",
      blueGrey: "#353c53",
      grey: "#DDDDDD",
      darkGrey: "#F4F4F4",
      blue: "#385ab5",
      bgGrey: "#F6F9FE",
      inactiveGrey: "#808080",
      red: "#f44336"
    }
  })
}
