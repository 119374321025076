import React from "react"
import { useContext, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import {
  convertDateTimeToString,
  getDateAndTimeOffline
} from "main/util/DateTimeHelper"
import { Button, CircularProgress, Grid } from "@mui/material"
import CustomerReceipt from "../receipt/CustomerReceipt"
import { OrderTypes } from "../constant/PosConstant"
import PrintIcon from "@mui/icons-material/Print"

const ProvisionalReceipt = ({ holdOrder }: any) => {
  const posContext = useContext(PosContext) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType
  const customerContext = useContext(PosCustomerContext) as PosCustomerType
  const [printReceiptLoading, setPrintReceiptLoading] = useState(false)
  const merchantData = posContext.merchantProfileData
  const masterData = posContext.masterProfileData?.master
  const merchantIntegrations = posContext.merchantIntegrations

  const customerReceiptRef = useRef<any>()

  const handleCustomerReceipt = useReactToPrint({
    content: () => customerReceiptRef.current
  })

  function handlePromise(func: () => void) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(func())
      }, 2000)
    })
  }

  function printInvoiceOnly() {
    setPrintReceiptLoading(true)
    handlePromise(handleCustomerReceipt).then(() => {
      setPrintReceiptLoading(false)
    })
  }

  async function saveOrder() {
    printInvoiceOnly()
  }

  const bill = {
    subTotal: productContext.getProductsSubTotal(),
    totalAmount: productContext.calculateProductsTotal(),
    taxAmount: posContext.tax,
    discountLine: {
      discountedAmount: posContext.discount
    }
  }

  const customerDetails = {
    name: (holdOrder ? holdOrder?.data?.customer : customerContext)
      ?.customerName,
    phoneNumber: (holdOrder ? holdOrder?.data?.customer : customerContext)
      ?.customerPhone,
    alternatePhoneNumber: (holdOrder
      ? holdOrder?.data?.customer
      : customerContext
    )?.alternatePhoneNumber
  }

  const orderDetails = {
    offlineOrderDate: getDateAndTimeOffline(convertDateTimeToString()),
    orderType: OrderTypes.WalkIn,
    orderIdentifier: posContext.orderIdentifier
  }

  const data = {
    order: {
      orderedProducts: productContext?.selectedProductsList,
      ...bill,
      customerDetails,
      ...orderDetails,
      temp: {}
    }
  }

  data.order.temp = data.order

  const holdData = {
    order: {
      orderedProducts: holdOrder?.data?.products,
      customerDetails,
      ...holdOrder?.data,
      ...orderDetails,
      discountLine: {
        discountedAmount: holdOrder?.data?.discountLine?.discount,
        discountType: holdOrder?.data?.discountLine?.discountType
      },
      temp: {}
    }
  }

  holdData.order.temp = holdData.order

  return (
    <>
      <Button variant="outlined" fullWidth onClick={saveOrder}>
        {printReceiptLoading ? (
          <CircularProgress size={20} />
        ) : (
          <PrintIcon color="primary" />
        )}
      </Button>

      <Grid container style={{ display: "none" }}>
        <CustomerReceipt
          ref={customerReceiptRef}
          merchantData={merchantData}
          data={holdOrder ? holdData : data}
          isFBRIntegrated={Boolean(posContext.FBRPosId)}
          merchantIntegrations={merchantIntegrations}
          masterData={masterData}
          prd={true}
        />
      </Grid>
    </>
  )
}
export default ProvisionalReceipt
